import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
// import reportWebVitals from './reportWebVitals';


// Find the div with the ID 'root' in your index.html file
const container = document.getElementById('root')!;
// Create a root for your application
const root = createRoot(container); // If you're using TypeScript, the '!' is a non-null assertion operator

// Render your app into the root
root.render(
	// <React.StrictMode>
	  <App />
	// </React.StrictMode>
);



// not used //////////////////
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
