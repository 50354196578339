import React, { useState, useEffect, useRef } from "react";
// import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';

import { Container, Row, Col, Navbar, Nav, Card, Modal } from "react-bootstrap"; // Import specific components
import HomeContainer from "./HomeContainer";

interface ImageData {
	[key: string]: string[]; // This assumes all values in the object are string arrays
}


interface ImageModalProps {
	show: boolean;
	onHide: () => void;
	selectedImage: string | null; // maybe it is null initially
	currentChapter: string;
}

// function ImageModalFUNCTION(props: ImageModalProps) {
// 	return (
// 		<Modal
// 			{...props}
// 			size='lg'
// 			aria-labelledby='ontained-modal-title-vcenter'
// 			centered
// 		>
// 			<Modal.Header /*closeButton*/ >
// 				<Modal.Title id='contained-modal-title-vcenter'>
// 					Image Preview
// 				</Modal.Title>
// 			</Modal.Header>
// 			<Modal.Body>
// 				{props.selectedImage && (
// 					<img src={`/images/${props.currentChapter}/thumbnails/{props.selectedImage}`}
// 						alt='Selected'
// 						className='img-fluid'/>
// 				)}
// 			</Modal.Body>
// 		</Modal>
// 	)
// }


const onHideModal = () => {
	window.history.back();
};




const ImageModal: React.FC<ImageModalProps> = (
	{ show, onHide, selectedImage, currentChapter }
) => {

	// Handle the click in empty space around the image (overlay),
	// to close the image
	const handleOverlayClick = (e: React.MouseEvent) => {
		// prevent modal from closing when the image itself is clicked
		// e.stopPropagation();
		//window.history.back(); // mimics the click on browsers back arrow
	};

	const handleImageClick = (e: React.MouseEvent) => {
		//e.stopPropagation(); // stops the click event from reaching the overlay ???
	};

	return (
		<Modal
			// {...props}
			className='modal-big-image'
			show={show}
			onHide={onHide}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
			//onClick={handleOverlayClick} // detect clicks around the image
		>
			<Modal.Header /*closeButton*/ >
				{/* <Modal.Title id='contained-modal-title-vcenter'>
					Image Preview
				</Modal.Title> */}
			</Modal.Header>
			<Modal.Body>
				{selectedImage && (
					<img src={`/images/${currentChapter}/${selectedImage}`}
						alt='Selected'
						className='img-fluid modal-img'
						//onClick={handleImageClick} // ignore click on image
					/>
				)}
			</Modal.Body>
		</Modal>
	)
}




const App: React.FC = () => {
	const [currentChapter, setCurrentChapter] = useState("home");
	const [imageData, setImageData] = useState<ImageData>({});
	const [modalShow, setModalShow] = useState(false);
	const [selectedImage, setSelectedImage] = useState("");

	const currentChapterRef = useRef(currentChapter);

	console.log('\nStart APP');
	console.log('history.state:', window.history.state);
	console.log('currChapter:', currentChapter);
	
	// Fetch image names from .json and store them into ImageData
	useEffect(() => {
		console.log('useEffects 1, fetch .json');
		const chapter = currentChapter; // For history it must always be the same key
		window.history.pushState(
			{ chapter },
			'', // `Selected Chapter: ${currentChapter}`,
			// `#${currentChapter}`
		);
		console.log('      history.state 2:', window.history.state);

		fetch("/images/image_names.json")
			.then((response) => {
				if (!response.ok) {
					throw new Error('Network respoonse was not ok');
				}
				// console.log('RESPONSE: ', response);
				return response.json();
			})
			.then((data) => setImageData(
					data.reduce(
						(acc: object, curr: object) => ({ ...acc, ...curr }),
						{}
					)
				)
			)
			.catch((error: Error) => {
				console.error('Could not fetch the data', error);
			});
			// console.log('     imageData: ' +  JSON.stringify(imageData, null, 2));
	}, []);

	
	// Listen to browser's back/forth button clicks
	useEffect(() => {
		console.log('useEffects 2: handle Pop state()');

	// CLICKING IN THE EMPTY SPACE, WHEN IMAGE IS ENLARGED, DOES NOT POP THE HISTORY, WHICH CAUSES THE BACK ARROW TO DO NOTHING ON THE FIRST CLICK, 

		const handlePopState = (event: PopStateEvent) => {
			const state = event.state;
			console.log('     HandlePOP state, event.state: ' + JSON.stringify(event.state, null, 2));

			if (state) {
				if (state.image) {
					// Remove 'active' class from current chapter
					console.log('    POP STATE of:   ' + currentChapterRef.current);
					console.log('    setCurrChapter: ' + event.state.chapter);
					// An image was opened, so show it
					setSelectedImage(state.image);
					setModalShow(true);
				} else {
					// No image is specified, show chapter thumbnails
					setModalShow(false);
					setSelectedImage('');
				}
				setCurrentChapter(event.state.chapter);
			} else {
				// Fallback to the initial home screen 
				setModalShow(false);
				setSelectedImage('');
				setCurrentChapter('home');
			}
		};

		window.addEventListener('popstate', handlePopState);
		return () => window.removeEventListener('popstate', handlePopState);
	}, []);



	const handleNavClick = (chapter: string) => {
		console.log('    handleNavCLick --> push state: ' + chapter);
		window.history.pushState(
			{ chapter },
			'', // `Selected Chapter: ${chapter}`, // can be empty string; intended for use in future browsers
			// `#${chapter}`
		);
		(document.activeElement as HTMLElement)?.blur(); // This removes all focused/ active statuses from elements. Otherwise on link would remain colored as active, after clicking the back arrow, and it was not possible to get rid of this bug ???

		setCurrentChapter(chapter);
		console.log('    currChapter: ' + currentChapter);
	}
	

	useEffect(() => {
		console.log(`Clicked chapter ${currentChapter}`);
		currentChapterRef.current = currentChapter;
	}, [currentChapter]);


	return (
	
		<div className="top-div d-flex flex-column" style={{ minHeight: "100vh" }}>
			<header>
				<Navbar className='navigation-menu pb-0' bg="" expand="sm">
					<Container className='justify-content-center'>
						{/* <Navbar.Brand href="#">Navbar</Navbar.Brand> */}
						<Navbar.Toggle aria-controls="basic-navbar-nav" />
						<Navbar.Collapse id="basic-navbar-nav">
							<Nav className="me-auto">
								<Nav.Link
									active={currentChapter == 'home'}
									onClick={() => handleNavClick('home')}>
									Home
								</Nav.Link>
								<Nav.Link
									active={currentChapter == 'crayons'}
									onClick={() => handleNavClick('crayons')}>
									Crayons
								</Nav.Link>
								<Nav.Link
									active={currentChapter == 'acrylic'}
									onClick={() => handleNavClick('acrylic')}>
									Acrylic
								</Nav.Link>
								<Nav.Link
									active={currentChapter == 'clay'}
									onClick={() => handleNavClick('clay')}>
									Clay
								</Nav.Link>
								<Nav.Link
									active={currentChapter == 'photoshop'}
									onClick={() => handleNavClick('photoshop')}>
									Photoshop
								</Nav.Link>
								<Nav.Link
									active={currentChapter == 'mouse'}
									onClick={() => handleNavClick('mouse')}>
									Mouse
								</Nav.Link>
							</Nav>
						</Navbar.Collapse>
					</Container>
				</Navbar>
			</header>

		<main>
			
		{currentChapter === 'home' ? (
				
				// Display a single thumbnail for each chapter when 'Home' is selected
				<HomeContainer handleNavClick={handleNavClick} />

			) : (
				
				<Container className=''>
					{/* // Display thumbnails for the selected chapter */}
					<Row className="d-flex justify-content-center align-items-end w-100 pt-5">
						{imageData[`./images/${currentChapter}/thumbnails/`]?.map((imageName) => (
							<Col xs={6} sm={5} md={4} lg={3} xl={2}
								 className="mt-5 mt-md-5 mt-lg-5
								 			mx-1 mx-sm-2 mx-md-3 mx-lg-4"
								 key={imageName}>
								<Card className='chapter-thumbnail-card'
									  onClick={() => {
										  setSelectedImage(imageName);
										  console.log('Sel. image: ' + selectedImage);
										  setModalShow(true);
										  const newState = { chapter: currentChapter, image: imageName };
										  window.history.pushState(newState, ''); //   , `#${currentChapter}/${imageName}`);
								}}>
									<Card.Img className='card-img' variant="center" src={`/images/${currentChapter}/thumbnails/${imageName}`} />
									<Card.Body>
										<Card.Title>{imageName.split('.')[0]}</Card.Title>
									</Card.Body>
								</Card>
							</Col>
						))}
					</Row>

					<ImageModal show={modalShow}
								onHide={() => {
									setModalShow(false)
									onHideModal();
								}}
								selectedImage={selectedImage}	
								currentChapter={currentChapter}
					/>

				</Container>
			)}
	
		</main>


			<footer>
				&copy; Jaka Murovec Banic
			</footer>
		</div>



	);
};

export default App;
