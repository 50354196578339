import { Container, Row, Col, Navbar, Nav, Card } from "react-bootstrap"; // Import specific components

interface HomeContainerProps {
	handleNavClick: (chapter: string) => void;
}


const HomeContainer: React.FC<HomeContainerProps> = (props) => {
	return (
	<>
	<Container className="flex-grow-1 d-flex flex-column align-items-center justify-content-center">
		
		<Row className="title-center justify-content-center">
			{/* <Col> */}
				<h2>Welcome!</h2>
				<h4>Have a look at some of my artefacts</h4>
			{/* </Col> */}
		</Row>

		<Row className="justify-content-center">
			
			<Col xs={6} md={4} lg={2} className="my-3" key='mouse'>
				{/* <Card onClick={() => handleNavClick(chapter.replace('./images/', '').replace('/thumbnails/', ''))}> */}
				<Card className='home-thumbnail-card' onClick={() => props.handleNavClick('mouse')}>
					{/* Placeholder for chapter thumbnails */}
					<Card.Img className='home-thumbnail-card-img'
						variant="center" src={`/images/mouse/thumbnails/_home_thumbnail.jpg`}
					/>
					<Card.Body>
						<Card.Title>Mouse</Card.Title>
					</Card.Body>
				</Card>
			</Col>

			<Col xs={6} md={4} lg={2} className="my-3" key='acrylic'>
				{/* <Card onClick={() => handleNavClick(chapter.replace('./images/', '').replace('/thumbnails/', ''))}> */}
				<Card className='home-thumbnail-card' onClick={() => props.handleNavClick('acrylic')}>
					{/* Placeholder for chapter thumbnails */}
					<Card.Img className='home-thumbnail-card-img'
						variant="center" src={`/images/acrylic/thumbnails/_home_thumbnail.jpg`}
					/>
					<Card.Body>
						<Card.Title>Acrylic</Card.Title>
					</Card.Body>
				</Card>
			</Col>

			<Col xs={6} md={4} lg={2} className="my-3" key='photoshop'>
				{/* <Card onClick={() => handleNavClick(chapter.replace('./images/', '').replace('/thumbnails/', ''))}> */}
				<Card className='home-thumbnail-card' onClick={() => props.handleNavClick('photoshop')}>
					{/* Placeholder for chapter thumbnails */}
					<Card.Img className='home-thumbnail-card-img'
						variant="center" src={`/images/photoshop/thumbnails/_home_thumbnail.jpg`}
					/>
					<Card.Body>
						<Card.Title>Photoshop</Card.Title>
					</Card.Body>
				</Card>
			</Col>

			<Col xs={6} md={4} lg={2} className="my-3" key='crayons'>
				{/* <Card onClick={() => handleNavClick(chapter.replace('./images/', '').replace('/thumbnails/', ''))}> */}
				<Card className='home-thumbnail-card' onClick={() => props.handleNavClick('crayons')}>
					{/* Placeholder for chapter thumbnails */}
					<Card.Img className='home-thumbnail-card-img'
						variant="center" src={`/images/crayons/thumbnails/_home_thumbnail.jpg`}
					/>
					<Card.Body>
						<Card.Title>Crayons</Card.Title>
					</Card.Body>
				</Card>
			</Col>

			<Col xs={6} md={4} lg={2} className="my-3" key='clay'>
				{/* <Card onClick={() => handleNavClick(chapter.replace('./images/', '').replace('/thumbnails/', ''))}> */}
				<Card className='home-thumbnail-card' onClick={() => props.handleNavClick('clay')}>
					{/* Placeholder for chapter thumbnails */}
					<Card.Img className='home-thumbnail-card-img'
						variant="center" src={`/images/clay/thumbnails/_home_thumbnail.jpg`}
					/>
					<Card.Body className='chapter-thumb-body'>
						<Card.Title>Clay</Card.Title>
					</Card.Body>
				</Card>
			</Col>

		</Row>
	</Container>
	</>
	)
}

export default HomeContainer;